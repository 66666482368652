<template>
  <div class="main-panel">
    <div class="chat-panel">
      <BaseChatView :model-value="message" />
    </div>
    <form class="chat-form" @submit.prevent="sendForm">
      <div class="send-messagebox">
        <BaseInputMessage
          v-model="inputMessage"
          label="Aa"
          :messageType="messageType"
          @on-file-change="onFileChange"
        />
      </div>
    </form>
    <div
      class="BaseButton"
      v-if="messageType === 'warning endchat' || isEndChat"
    >
      <span class="text-end-chat">สิ้นสุดการสนทนาแล้ว</span>
      <BaseButton Title="ปิดการสนทนา" class="mt-4" @click="closeWidget()">
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import usePusher from '@/use/pusher'
import { useStore } from 'vuex'

export default {
  data() {
    return {
      score_01: require('@/assets/sendmessage.svg'),
      score_02: require('@/assets/uploadfile.svg'),
      // messageType: this.$store.state.messageType,
    }
  },
  setup() {
    const { inputMessage, onFileChange, sendForm } = usePusher()
    const store = useStore()

    return {
      inputMessage,
      sendForm,
      onFileChange,
      message: computed(() => store.getters.getMessages),
      messageType: computed(() => store.getters.getMessageType),
      isEndChat: computed(() => store.getters.getIsEndChat),
    }
  },
  mounted() {
    this.greetingTime()
    this.greetingMsg()
    window.addEventListener('scroll', this.handleScroll, true)
    window.parent.postMessage(
      {
        widgetTitle: 'การสนทนา',
      },
      '*'
    )
  },
  updated() {
    console.log(this.$store.state.messageType)
    // this.messageType = this.$store.state.messageType;
  },
  methods: {
    EndChat() {
      let $this = this
      $this.$router.push(`/survey/${this.$store.state.sessionId}/th`)
    },

    addZero(num) {
      if (num < 10) {
        return '0' + num
      }
      return num
    },

    greetingTime() {
      let date = new Date()
      let hour = date.getHours()
      let min = date.getMinutes()
      let time = this.addZero(hour) + ':' + this.addZero(min)
      let msg = {
        message: time,
        sender: 'start-time',
      }
      this.$store.commit('updateStoreMessages', msg)
    },

    greetingMsg() {
      let greetingMessages = this.$store.state.greetingMessages
      if (
        greetingMessages &&
        greetingMessages.length != null &&
        greetingMessages.length > 0
      ) {
        for (let i = 0; i < greetingMessages.length; i++) {
          let data = {
            message: greetingMessages[i],
            sender: 'bot',
            messageType: 'text',
            date: new Date(),
          }
          // axios.post(`${config.baseUrl}/webhook/message`, data).then((res) => {
          //   console.log(res)
          // })
          this.$store.commit('updateStoreMessages', data)
        }
      }

      // axios.post(`${config.baseUrl}/webhook/message`, data).then((res) => {
      //   console.log(res)
      // })
    },
    closeWidget() {
      window.parent.postMessage(
        {
          closeWidget: true,
        },
        '*'
      )
    },
    handleScroll(e) {
      let el = e.target
      if (el.classList.contains('on-scrollbar') === false) {
        el.classList.add('on-scrollbar')
      }
      setTimeout(() => {
        el.classList.remove('on-scrollbar')
      }, 300)
    },
  },
}
</script>

<style scoped>
.text-end-chat {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  text-align: center;

  color: #9eafc1;
}
.BaseButton {
  padding: 10px;
}
.content-wrapper {
  display: flex;
  margin-bottom: 20px;
}
/* .send-messagebox {
    display: flex;
  } */
.input-message {
  /* Input */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  gap: 8px;

  position: absolute;
  width: 350px;
  height: 44px;

  background: require('@/assets/sendmessage.svg');
  border-radius: 16px;
}

.main-panel {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 100vh;
}

.chat-panel {
  overflow: overlay;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: transparent;
}

.on-scrollbar::-webkit-scrollbar-track {
  background-color: #dfe6ec;
}

.on-scrollbar::-webkit-scrollbar-thumb {
  background-color: #0050f0;
}

.chat-form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: auto;
  padding: 20px;
}
.chat-form {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  margin-top: auto;
  padding: 10px;
}
.sendmessage-svg {
  position: absolute;
  padding-left: 250px;
  padding-top: 13px;
  margin: auto;
  background: #f5f4f6;
  border-radius: 16px;
}
.uploadfile-svg {
  padding-top: 13px;
  padding-right: 15px;
}
</style>
